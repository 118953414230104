import React, { Component } from 'react';
import './report.css';

/* import { Table } from 'react-bootstrap'; */
import BootstrapTable from 'react-bootstrap-table-next';
//import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';

import * as appUtils from './utils';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class ReportVersionInstalls extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      apiurl: 'installs',
      data: null,
      version_groups: null,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    var url = appUtils.buildURL(this.state.apiurl, [
      this.props.filters.url_daterange,
      this.props.filters.url_app_identifiers,
    ]);
    this.doFetchData(url);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      var url = appUtils.buildURL(this.state.apiurl, [
        this.props.filters.url_daterange,
        this.props.filters.url_app_identifiers,
      ]);
      this.doFetchData(url);
    }
  }

  render() {
		var csvurl = appUtils.buildURL(this.state.apiurl, [
			 'format=csv',
			 this.props.filters.url_daterange,
			 this.props.filters.url_app_identifiers,
		]);
    if (this.state.data == null) return <></>;
    else {
      const columns = [
        {
          dataField: 'version',
          text: 'Version',
        },
        {
          dataField: 'release_date',
          text: 'Release Date',
        },
        {
          dataField: 'version_group_name',
          text: 'Version Group',
        },
        {
          dataField: 'version_group',
          text: 'Group Id',
        },
        {
          dataField: 'count',
          text: 'Install Count',
        },
      ];

      return (
        <>
          <div className="report-download">
            <a href={csvurl}>Download the data of this table as CSV file</a>
          </div>
          <div className="tablewrapper">
            <BootstrapTable striped hover keyField="row" version="4" columns={columns} data={this.state.data} />
          </div>
        </>
      );
    }
  }

  doFetchData(url) {
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            let transformed = data['installs_by_version'].map((val, ix) => {
              val.row = ix;
              return val;
            });
            this.setState({ data: transformed });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }
}

export default ReportVersionInstalls;
