import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

import ReportStatsPerDay from './report-stats-per-day';
import ReportStatsPerHour from './report-stats-per-hour';
import ReportAverageSessionLength from './report-average-session-length';

class PageSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Stats per month/week/day/hour</h2>
        <h3>
          {this.props.filters.startDate != null ? this.props.filters.startDate.toLocaleDateString() : ''} -{' '}
          {this.props.filters.endDate != null ? this.props.filters.endDate.toLocaleDateString() : ''}
        </h3>
        <ReportStatsPerDay
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportAverageSessionLength
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportStatsPerHour
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
      </div>
    );
  }
}

export default PageSessions;
