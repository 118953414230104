import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

import ReportMaterialsPerRoom from './report-materials-per-room';

class PagePerRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Per-room data</h2>
        <h3>
          {this.props.filters.startDate != null ? this.props.filters.startDate.toLocaleDateString() : ''} -{' '}
          {this.props.filters.endDate != null ? this.props.filters.endDate.toLocaleDateString() : ''}
        </h3>
        <ReportMaterialsPerRoom
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
      </div>
    );
  }
}

export default PagePerRoom;
