import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

import ReportAdvisorInstalls from './report-advisor-installs';

class PageAdvisorInstalls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Installs by advisor</h2>
        <ReportAdvisorInstalls
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
      </div>
    );
  }
}

export default PageAdvisorInstalls;
