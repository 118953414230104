import React, { Component } from 'react';
import './page.css';
import './report.css';

import { Button, Form } from 'react-bootstrap';

import * as appUtils from './utils';

class PageUpload extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      statusTxtAdvisors: '',
      statusTxtVouchers: '',
      statusTxtVouchersLeft: '',
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.doFetchData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.doFetchData();
    }
  }

  render() {
    return (
      <div className="page">
        <h2>Upload advisor list</h2>
        <Form>
          <p>
            Please click 'Upload advisors list' button, select 'Installs by advisor' XLSX file to upgade advisor list.'
          </p>
          <Button variant="primary" onClick={() => this.refs.fileUploaderAdvisors.click()}>
            Upload advisors list
          </Button>
          <input
            type="file"
            id="file"
            ref="fileUploaderAdvisors"
            accept=".xlsx"
            onChange={this.onChangeFileAdvisors.bind(this)}
            style={{ display: 'none' }}
          />
          <br />
          <p>
            <b>{this.state.statusTxtAdvisors}</b>
          </p>
        </Form>
        <hr />
        <h2>Upload iOS app store promo codes</h2>
        <h3>({this.state.statusTxtVouchersLeft})</h3>
        <Form>
          <p>Please click 'Upload vouchers' button, select XLSX file to upgade list of vouchers.'</p>
          <Button variant="primary" onClick={() => this.refs.fileUploaderVouchers.click()}>
            Upload vouchers
          </Button>
          <input
            type="file"
            id="file"
            ref="fileUploaderVouchers"
            accept=".xlsx"
            onChange={this.onChangeFileVouchers.bind(this)}
            style={{ display: 'none' }}
          />
          <br />
          <p>
            <b>{this.state.statusTxtVouchers}</b>
          </p>
        </Form>
      </div>
    );
  }

  onChangeFileAdvisors(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    let formData = new FormData();
    formData.append('file', file);
    var url = appUtils.apiBaseURL() + 'post_advisor';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            let txt = data.status + ', ' + data.reason;
            this.setState({ statusTxtAdvisors: txt });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }

  onChangeFileVouchers(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    let formData = new FormData();
    formData.append('file', file);
    var url = appUtils.apiBaseURL() + 'post_voucher';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            let txt = data.status + ', ' + data.reason;
            this.setState({ statusTxtVouchers: txt });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }

  doFetchData() {
    var url = appUtils.apiBaseURL() + 'vouchers_left';
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            if (this.mounted)
              this.setState({
                statusTxtVouchersLeft: 'Latest version is ' + data.version + ' with ' + data.left + ' vouchers left',
              });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }
}

export default PageUpload;
