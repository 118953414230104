import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

class PageCrashes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Crash reports</h2>
        <h3>
          {this.props.filters.startDate != null ? this.props.filters.startDate.toLocaleDateString() : ''} -{' '}
          {this.props.filters.endDate != null ? this.props.filters.endDate.toLocaleDateString() : ''}
        </h3>
        <h2>NOT YET!</h2>
      </div>
    );
  }
}

export default PageCrashes;
