import React, { Component } from 'react';
import './report.css';

/* import { Table } from 'react-bootstrap'; */
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';

import * as appUtils from './utils';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class ReportAdvisorInstalls extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      apiurl: 'advisors',
      data: null,
      version_groups: null,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    var url = appUtils.buildURL(this.state.apiurl, [
      this.props.filters.url_daterange,
      this.props.filters.url_app_identifiers,
    ]);
    this.doFetchData(url);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      var url = appUtils.buildURL(this.state.apiurl, [
        this.props.filters.url_daterange,
        this.props.filters.url_app_identifiers,
      ]);
      this.doFetchData(url);
    }
  }

  render() {
		var csvurl = appUtils.buildURL(this.state.apiurl , [
			 'format=csv',
			 this.props.filters.url_daterange,
			 this.props.filters.url_app_identifiers,
    ]);
    if (this.state.data == null) return <></>;
    else {
      const columns = [
        {
          dataField: 'id',
          text: 'Id',
          sort: true,
        },
        {
          dataField: 'name',
          text: 'Name',
          sort: true,
          style: { textAlign: 'left' },
        },
        {
          dataField: 'machine_id',
          text: 'Machine ID',
          sort: true,
        },
        {
          dataField: 'session_count',
          text: 'Sessions',
          sort: true,
        },
        {
          dataField: 'last_used_browser',
          text: 'Browser/Device',
          sort: true,
        },
        {
          dataField: 'max_version',
          text: 'Version',
          sort: true,
        },
        {
          dataField: 'max_version_group_name',
          text: 'Version group',
          sort: true,
          filter: selectFilter({
            options: this.state.version_groups,
          }),
        },
      ];
      const defaultSorted = [
        {
          dataField: 'name',
          order: 'asc',
        },
      ];

      return (
        <>
          <div className="report-download">
            <a href={csvurl}>Download the data of this table as CSV file</a>
          </div>
          <BootstrapTable
            striped
            hover
            keyField="row"
            version="4"
            columns={columns}
            data={this.state.data}
            filter={filterFactory()}
            defaultSorted={defaultSorted}
          />
        </>
      );
    }
  }

  doFetchData(url) {
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //                        if (this.mounted) this.setState({ data: finalData, maxval: maxval });
            var mangled = {};
            for (var x in data['version_group_names']) {
              mangled[data['version_group_names'][x]] = data['version_group_names'][x];
            }
            // let mangled = data['version_group_names'].map( (k,v) => { return {v: v}; });
            // mangled = data['version_group_names']
            let transformed = data['advisor_installs'].map((val, ix) => {
              val.row = ix;
              return val;
            });
            this.setState({ data: transformed, version_groups: mangled });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }
}

export default ReportAdvisorInstalls;
