import React, { Component } from 'react';
import './report.css';

import {} from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

import * as appUtils from './utils';

class ReportSampleRoom extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      apiurl: 'sample_room',
      ddata: null,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    var url = appUtils.buildURL(this.state.apiurl, [
      this.props.filters.url_daterange,
      this.props.filters.url_app_identifiers,
    ]);
    this.doFetchData(url);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      var url = appUtils.buildURL(this.state.apiurl, [
        this.props.filters.url_daterange,
        this.props.filters.url_app_identifiers,
      ]);
      this.doFetchData(url);
    }
  }

  render() {
    var chartTitle = 'Sample room use by image';
    chartTitle += this.props.filters.text_daterange;
    var barHeight = 100;
    var chartHeight =
      this.state.ddata == null
        ? '100%'
        : this.state.ddata.length * barHeight + //approximate bar size
          (this.state.ddata.length - 1) * 2 + //approximate gap size
          50 + //bottom axis and text, very inprecise
          'px';
    var csvurl = appUtils.buildURL(this.state.apiurl, [
      'format=csv',
      this.props.filters.url_daterange,
      this.props.filters.url_app_identifiers,
    ]);
    return (
      <>
        <div className="graph-title">
          <h3>{chartTitle}</h3>
        </div>
        <ResponsiveContainer width="95%" height="100%" minHeight={chartHeight} maxHeight={chartHeight}>
          <BarChart
            barCategoryGap={20}
            data={this.state.ddata}
            margin={{ top: 0, right: 30, left: 120, bottom: 0 }}
            layout="vertical"
            stackOffset="expand"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" scale="auto" tickFormatter={this.toPercent} />
            <YAxis dataKey="name" type="category" tick={(info) => this.customAxisLabel(info)} interval={0} />
            <Tooltip content={(info) => this.showTooltip(info)} />
            <Bar dataKey="value" fill="#3366cc">
              <LabelList dataKey="label" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {this.state.ddata != null && (
          <div className="report-download">
            <a href={csvurl}>Download the data of this graph as CSV file</a>
          </div>
        )}
      </>
    );
  }

  customAxisLabel(props) {
    console.log(JSON.stringify(props, null, 4));
    var url = appUtils.apiBaseURL() + 'static/sample_thumbs/' + props.payload.value;
    return (
      <g transform={`translate(${props.x - 125},${props.y - 60})`}>
        <image xlinkHref={url} x={0} y={0} height="120px" width="120px" textAnchor="middle" fill="#666" />
      </g>
    );
  }

  toPercent(decimal, fixed = 0) {
    return `${(decimal * 100).toFixed(fixed)}%`;
  }

  showTooltip(info) {
    if ('payload' in info) {
      if (Array.isArray(info.payload)) {
        if (info.payload.length > 0) {
          if (info.payload[0]) {
            if ('payload' in info.payload[0]) {
              return <div className="custom-tooltip">{info.payload[0].payload.tooltip}</div>;
            }
          }
        }
      }
    }
    return null;
  }

  doFetchData(url) {
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log(JSON.stringify(data, null, 4));
            var total = 0;
            for (var i in data) total += data[i][1];
            var finalData = [];
            for (i in data) {
              var frac = data[i][1] / total;
              finalData.push({
                name: data[i][0],
                value: frac,
                tooltip: 'Used by ' + (100.0 * frac).toFixed(1) + '% of all sessions',
                label: (100.0 * frac).toFixed(1) + '%',
              });
            }

            if (this.mounted) this.setState({ ddata: finalData });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }
}

export default ReportSampleRoom;
