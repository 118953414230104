import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { Button, Container, Row, Col, Form, Alert, Modal } from 'react-bootstrap';
import DatePicker from 'react-date-picker';

import PageSessions from './page-sessions';
import PageFeatures from './page-features';
import PagePerVisit from './page-pervisit';
import PagePerRoom from './page-perroom';
import PageSampleRooms from './page-samplerooms';
import PageBlindTypes from './page-blindtypes';
import PagePlatformsBrowsers from './page-platformsbrowsers';
import PageCrashes from './page-crashes';
import PageAccountsGroups from './page-accounts-groups';
import PageAdvisorInstalls from './page-advisor-installs';
import PageVersionInstalls from './page-version-installs';
import PageVersionsGroups from './page-versions-groups';
import PageUpload from './page-upload';

import * as appUtils from './utils';

import logoimage from './resources/logo.png';
import spinnerimage from './resources/loading.gif';

//mainState = 0 - Initial /info fetch
//mainState = 1 - Needs to log in
//mainState = 2 - Logged in

class App extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    let whileAgo = new Date();
    //whileAgo.setFullYear(whileAgo.getFullYear() - 1);
    whileAgo.setDate(whileAgo.getDate() - 7 * 11); //12 weeks report
    this.state = {
      selected: 'sessions',
      //selected: 'upload',
      mainState: 0,
      account: '',
      app: '',
      user: '',
      edit: false,
      access_type: 99,
      show_accounts: false,
      show_advisors: false,
      show_blind_types: true,
      show_crashes: true,
      show_devices: false,
      show_frequency: false,
      show_installs: false,
      show_per_visit: true,
      un: '',
      pw: '',
      showLoginAlert: false,
      loadingCnt: 0,
      update_filters: 1,
      filters: {
        startDate: whileAgo,
        endDate: null,
        text_daterange: '',
        url_daterange: '',
        dailyMonthly: 3,
        url_dailymonthly: '',
        app_identifiers: {},
        url_app_identifiers: '',
      },
      functions: {
        failed: this.doFailed.bind(this),
        spinStart: this.doSpinStart.bind(this),
        spinStop: this.doSpinStop.bind(this),
      },
      // DEBUG: keep (false), change to (true) to show all the options
      debug: false,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    console.log('API url is set to ' + appUtils.apiBaseURL());
    document.title = 'Fotoquirk Analytics';
    if (this.state.mainState === 0) {
      this.doFetchInfo();
    }
  }

  render() {
    switch (this.state.mainState) {
      case 0:
        //Some loading banner usually goes here
        return null;
      case 1:
        return this.renderLogIn();
      default:
        return this.renderLoggedIn();
    }
  }

  renderLogIn() {
    return (
      <Modal show={true} onHide={() => this.modalGClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showLoginAlert === true && <Alert variant="danger">Login incorrect!</Alert>}
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Enter Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Username"
                value={this.state.un}
                onChange={this.handleUNChange.bind(this)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={this.state.pw}
                onChange={this.handlePWChange.bind(this)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={(e) => this.doLogin()}>
            LOG IN
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderLoggedIn() {
    return (
      <div className="App">
        <Container fluid>
          <div className="sidebar">
            {/* logo */}
            <img src={logoimage} alt="logo" className="logo" />

            {/* info details */}
            <Container>
              <Row>
                <Col>account:</Col>
                <Col>{this.state.account}</Col>
              </Row>
              <Row>
                <Col>app:</Col>
                <Col>{this.state.app}</Col>
              </Row>
              <Row>
                <Col>user:</Col>
                <Col>{this.state.user}</Col>
              </Row>
              <Row>
                <Col>edit:</Col>
                <Col>{this.state.edit ? 'Yes' : 'No'}</Col>
              </Row>
              <Button className="logoutButton" size="sm" onClick={() => this.clickLogout()}>
                logout
              </Button>
            </Container>

            {this.state.debug === true && <h3>Debug: ON</h3>}

            <div className="loadingcontainer">
              {this.state.loadingCnt > 0 && <img src={spinnerimage} alt="" className="loading" />}
            </div>

            {/* buttons */}
            <Button
              size="sm"
              block
              onClick={(e) => this.handleClick('sessions', e)}
              disabled={this.state.selected === 'sessions'}
            >
              Stats per day/hour
            </Button>
            <Button
              size="sm"
              block
              onClick={(e) => this.handleClick('features', e)}
              disabled={this.state.selected === 'features'}
            >
              Feature use
            </Button>
            {this.state.show_per_visit === true && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('pervisit', e)}
                disabled={this.state.selected === 'pervisit'}
              >
                Stats per visit/appointment
              </Button>
            )}
            <Button
              size="sm"
              block
              onClick={(e) => this.handleClick('perroom', e)}
              disabled={this.state.selected === 'perroom'}
            >
              Stats per room
            </Button>
            <Button
              size="sm"
              block
              onClick={(e) => this.handleClick('samplerooms', e)}
              disabled={this.state.selected === 'samplerooms'}
            >
              Sample rooms
            </Button>
            {this.state.show_blind_types === true && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('blindtypes', e)}
                disabled={this.state.selected === 'blindtypes'}
              >
                Product types
              </Button>
            )}
            {this.state.show_accounts === true && this.state.access_type >= 10 && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('accounts', e)}
                disabled={this.state.selected === 'accounts'}
              >
                Accounts
              </Button>
            )}
            {this.state.show_installs === true && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('advisor-installs', e)}
                disabled={this.state.selected === 'advisor-installs'}
              >
                Installs by advisor
              </Button>
            )}
            {this.state.show_installs === true && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('version-installs', e)}
                disabled={this.state.selected === 'version-installs'}
              >
                Installs by version
              </Button>
            )}
            {this.state.show_devices === true && (
              <Button
                size="sm"
                block
                onClick={(e) => this.handleClick('platformsbrowsers', e)}
                disabled={this.state.selected === 'platformsbrowsers'}
              >
                Platforms/browsers
              </Button>
            )}

            {/* date range pickers */}
            <div className="datediv">
              <p>Start</p>
              <DatePicker value={this.state.filters.startDate} onChange={this.handleStartDateChange.bind(this)} />
              <p>End</p>
              <DatePicker value={this.state.filters.endDate} onChange={this.handleEndDateChange.bind(this)} />
            </div>

            {/* Daily/Monthly */}
            {(this.state.selected === 'sessions' || this.state.selected === 'features') && (
              <Form className="periodForm">
                <Form.Check
                  checked={this.state.filters.dailyMonthly === 1}
                  type="radio"
                  id="radio-daily"
                  label="Daily"
                  name="dailymonthly"
                  onChange={() => {
                    let filters = this.state.filters;
                    // Daily range will reset dates
                    filters.endDate = null;
                    filters.startDate = new Date();
                    filters.startDate.setDate(filters.startDate.getDate() - 11); //12 days report
                    //
                    filters.dailyMonthly = 1;
                    filters = this.updateFilters(filters);
                    this.setState({
                      filters: filters,
                      update_filters: this.state.update_filters + 1,
                    });
                  }}
                />
                <Form.Check
                  checked={this.state.filters.dailyMonthly === 3}
                  type="radio"
                  id="radio-weekly"
                  label="Weekly"
                  name="dailymonthly"
                  onChange={() => {
                    let filters = this.state.filters;
                    // Weekly range will reset dates
                    filters.endDate = null;
                    filters.startDate = new Date();
                    filters.startDate.setDate(filters.startDate.getDate() - 7 * 11); //12 weeks report
                    //
                    filters.dailyMonthly = 3;
                    filters = this.updateFilters(filters);
                    this.setState({
                      filters: filters,
                      update_filters: this.state.update_filters + 1,
                    });
                  }}
                />
                <Form.Check
                  checked={this.state.filters.dailyMonthly === 2}
                  type="radio"
                  id="radio-monthly"
                  label="Monthly"
                  name="dailymonthly"
                  onChange={() => {
                    let filters = this.state.filters;
                    // Monthly range will reset dates
                    filters.endDate = null;
                    filters.startDate = new Date();
                    filters.startDate.setMonth(filters.startDate.getMonth() - 11); //12 months report
                    //
                    filters.dailyMonthly = 2;
                    filters = this.updateFilters(filters);
                    this.setState({
                      filters: filters,
                      update_filters: this.state.update_filters + 1,
                    });
                  }}
                />
              </Form>
            )}

            {/* App identifiers */}
            <Form className="appIdentifierForm">
              {Object.keys(this.state.filters.app_identifiers).map((app_id, index) => (
                <Form.Check
                  type="checkbox"
                  checked={this.state.filters.app_identifiers[app_id]}
                  label={app_id}
                  key={index}
                  id={'appident' + app_id}
                  onChange={() => {
                    var filters = this.state.filters;
                    let app_identifiers = filters.app_identifiers;
                    app_identifiers[app_id] = !app_identifiers[app_id];
                    var is_selected = false;
                    for (var appid in app_identifiers) {
                      if (app_identifiers[appid] === true) is_selected = true;
                    }
                    if (!is_selected) {
                      var first_key = Object.keys(app_identifiers)[0];
                      app_identifiers[first_key] = true;
                    }
                    filters.app_identifiers = app_identifiers;
                    filters = this.updateFilters(filters);
                    this.setState({
                      filters: filters,
                      update_filters: this.state.update_filters + 1,
                    });
                  }}
                />
              ))}
            </Form>

            {/* Superuser */}
            <div className="superuserdiv">
              {this.state.access_type >= 99 && (
                <Button
                  size="sm"
                  block
                  onClick={(e) => this.handleClick('versions-groups', e)}
                  disabled={this.state.selected === 'versions-groups'}
                >
                  Edit versions
                </Button>
              )}
              {this.state.access_type >= 99 && (
                <Button
                  size="sm"
                  block
                  onClick={(e) => this.handleClick('upload', e)}
                  disabled={this.state.selected === 'upload'}
                >
                  Upload
                </Button>
              )}
              {this.state.show_crashes === true && this.state.access_type >= 99 && (
                <Button
                  size="sm"
                  block
                  onClick={(e) => this.handleClick('crashes', e)}
                  disabled={this.state.selected === 'crashes'}
                >
                  Crashes
                </Button>
              )}
            </div>
          </div>

          <div className="reports">
            {/* report pages/tabs */}
            {this.state.selected === 'sessions' && (
              <PageSessions
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'features' && (
              <PageFeatures
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'pervisit' && (
              <PagePerVisit
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'perroom' && (
              <PagePerRoom
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'samplerooms' && (
              <PageSampleRooms
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'blindtypes' && (
              <PageBlindTypes
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'accounts' && (
              <PageAccountsGroups account={this.state.account} functions={this.state.functions} />
            )}
            {this.state.selected === 'platformsbrowsers' && (
              <PagePlatformsBrowsers
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'crashes' && (
              <PageCrashes
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'advisor-installs' && (
              <PageAdvisorInstalls
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'version-installs' && (
              <PageVersionInstalls
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
              />
            )}
            {this.state.selected === 'versions-groups' && (
              <PageVersionsGroups
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
                account={this.state.account}
              />
            )}
            {this.state.selected === 'upload' && (
              <PageUpload
                update_filters={this.state.update_filters}
                filters={this.state.filters}
                functions={this.state.functions}
                account={this.state.account}
              />
            )}
          </div>
        </Container>
      </div>
    );
  }

  updateFilters(filters) {
    // filters.text_daterange from startDate, endDate
    filters.text_daterange = '';
    if (filters.startDate != null || filters.endDate != null) {
      filters.text_daterange =
        ', ' +
        (filters.startDate != null ? filters.startDate.toLocaleDateString() + ' ' : '') +
        '-' +
        (filters.endDate != null ? ' ' + filters.startDate.toLocaleDateString() : '');
    }
    // filters.url_daterange from startDate, endDate
    var startDateUnix = filters.startDate != null ? parseInt((filters.startDate.getTime() / 1000).toFixed(0)) : 0;
    var endDateUnix = filters.endDate != null ? parseInt((filters.endDate.getTime() / 1000).toFixed(0)) : 0;
    filters.url_daterange = 'startDate=' + startDateUnix + '&endDate=' + endDateUnix;
    // filters.url_dailymonthly from dailyMonthly
    filters.url_dailymonthly = 'period=' + filters.dailyMonthly;
    // filters.url_app_identifiers from app_identifiers
    var count = 0;
    var total = 0;
    filters.url_app_identifiers = '';
    for (var app_id in filters.app_identifiers) {
      if (filters.app_identifiers[app_id] === true) {
        filters.url_app_identifiers += app_id + ',';
        count++;
      }
      total++;
    }
    if (total > 0) {
      if (total === count) {
        filters.url_app_identifiers = '';
      } else {
        if (count === 0) {
          filters.url_app_identifiers = 'none';
        }
      }
    } else {
      filters.url_app_identifiers = '';
    }
    if (filters.url_app_identifiers.endsWith(',')) {
      filters.url_app_identifiers = filters.url_app_identifiers.slice(0, -1);
    }
    if (filters.url_app_identifiers.length > 0) {
      filters.url_app_identifiers = 'appIdentifiers=' + filters.url_app_identifiers;
    }
    //
    //console.log(JSON.stringify(filters, null, 4));
    return filters;
  }

  handleClick(id, e) {
    this.setState({ selected: id });
  }

  handleStartDateChange(date) {
    let filters = this.state.filters;
    filters.startDate = date;
    filters = this.updateFilters(filters);
    this.setState({ filters: filters, update_filters: this.state.update_filters + 1 });
    //console.log("START DATE");
    //console.log(date);
  }

  handleEndDateChange(date) {
    let filters = this.state.filters;
    filters.endDate = date;
    filters = this.updateFilters(filters);
    this.setState({ filters: filters, update_filters: this.state.update_filters + 1 });
    //console.log("END DATE");
    //console.log(date);
  }

  handleUNChange(e) {
    this.setState({ un: e.target.value });
  }

  handlePWChange(e) {
    this.setState({ pw: e.target.value });
  }

  doNeedsLogin(showAlert) {
    this.setState({
      un: '',
      pw: '',
      showLoginAlert: showAlert,
      mainState: 1,
    });
  }

  doNewInfoReceived(data) {
    if (this.state.debug) {
      data.show_accounts = true;
      data.show_advisors = true;
      data.show_blind_types = true;
      data.show_crashes = true;
      data.show_devices = true;
      data.show_frequency = true;
      data.show_installs = true;
      data.show_per_visit = true;
    }
    //
    if (this.mounted) {
      //console.log('Info', JSON.stringify(data, null, 4));
      var filters = this.state.filters;
      var app_identifiers = filters.app_identifiers;
      for (var app_id of data.app_identifiers) {
        app_identifiers[app_id] = true;
      }
      filters.app_identifiers = app_identifiers;
      filters = this.updateFilters(filters);
      this.setState({
        mainState: 2,
        account: data.account,
        app: data.app,
        user: data.user,
        edit: data.edit,
        access_type: data.access_type,
        show_accounts: data.show_accounts,
        show_advisors: data.show_advisors,
        show_blind_types: data.show_blind_types,
        show_crashes: data.show_crashes,
        show_devices: data.show_devices,
        show_frequency: data.show_frequency,
        show_installs: data.show_installs,
        show_per_visit: data.show_per_visit,
        filters: filters,
        update_filters: this.state.update_filters + 1,
      });
    }
  }

  doFailed() {
    this.setState({
      un: '',
      pw: '',
      showLoginAlert: false,
      mainState: 1,
    });
  }

  doSpinStart() {
    this.setState((state) => ({ loadingCnt: state.loadingCnt + 1 }));
  }

  doSpinStop() {
    this.setState((state) => ({ loadingCnt: state.loadingCnt <= 0 ? 0 : state.loadingCnt - 1 }));
  }

  doLogin() {
    let messageFinal = JSON.stringify({ un: this.state.un, pw: this.state.pw });
    var url = appUtils.apiBaseURL() + 'login';
    fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, same-origin, *omit
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: messageFinal, // body data type must match "Content-Type" header
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if ('error' in data) {
              this.doNeedsLogin(true);
            } else {
              if ('account' in data) {
                this.doNewInfoReceived(data);
              } else {
                this.doNeedsLogin(true);
              }
            }
          });
        } else {
          console.log('error 1');
          this.doNeedsLogin(true);
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.doNeedsLogin(true);
      });
  }

  doFetchInfo() {
    var url = appUtils.apiBaseURL() + 'info';
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            //console.log('Info', JSON.stringify(data, null, 4));
            if ('error' in data) {
              this.doNeedsLogin(false);
            } else {
              if ('account' in data) {
                this.doNewInfoReceived(data);
              } else {
                this.doNeedsLogin(false);
              }
            }
          });
        } else {
          console.log('error 1');
          this.doNeedsLogin(false);
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.doNeedsLogin(false);
      });
  }

  clickLogout() {
    var url = appUtils.apiBaseURL() + 'logout';
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            this.doNeedsLogin(false);
          });
        } else {
          console.log('error 1');
          this.doNeedsLogin(false);
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.doNeedsLogin(false);
      });
  }
}

export default App;
