const apiBaseURL = () => {
  var url = process.env.REACT_APP_API_URL || 'http://localhost:5000/';
  if (!url.endsWith('/')) url += '/';
  return url;
};

const buildURL = (base, segments) => {
  var finalurl = apiBaseURL() + base;
  var first = true;
  for (var s in segments) {
    var segment = segments[s];
    if (segment.length > 0) {
      if (first === true) {
        finalurl += '?';
        first = false;
      } else {
        finalurl += '&';
      }
      finalurl += segment;
    }
  }
  return finalurl;
};

export { apiBaseURL, buildURL };
