import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

import ReportStatsTime from './report-stats-time';
import ReportStatsSessions from './report-stats-sessions';
import ReportStatsStartup from './report-stats-startup';

class PagePerVisit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Per-visit data</h2>
        <h3>
          {this.props.filters.startDate != null ? this.props.filters.startDate.toLocaleDateString() : ''} -{' '}
          {this.props.filters.endDate != null ? this.props.filters.endDate.toLocaleDateString() : ''}
        </h3>
        <ReportStatsTime
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportStatsSessions
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportStatsStartup
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
      </div>
    );
  }
}

export default PagePerVisit;
