import React, { Component } from 'react';
import './page.css';

import {} from 'react-bootstrap';

import ReportFeatureCompare from './report-feature-compare';
import ReportFeatureSampleRooms from './report-feature-sampleroom';
import ReportFeatureSaveRemote from './report-feature-saveremote';
import ReportFeatureDownloadJPG from './report-feature-download-jpg';

class PageFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="page">
        <h2>Fraction of sessions using a particular feature</h2>
        <h3>
          {this.props.filters.startDate != null ? this.props.filters.startDate.toLocaleDateString() : ''} -{' '}
          {this.props.filters.endDate != null ? this.props.filters.endDate.toLocaleDateString() : ''}
        </h3>
        <ReportFeatureCompare
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportFeatureSampleRooms
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportFeatureSaveRemote
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
        <ReportFeatureDownloadJPG
          update_filters={this.props.update_filters}
          filters={this.props.filters}
          functions={this.props.functions}
        />
      </div>
    );
  }
}

export default PageFeatures;
