import React, { Component } from 'react';
import './report.css';

import {} from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, Legend } from 'recharts';

import * as appUtils from './utils';

class ReportFeatureSaveRemote extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      apiurl: 'feature_histogram_save_remote',
      ddata: null,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    var url = appUtils.buildURL(this.state.apiurl, [
      this.props.filters.url_daterange,
      this.props.filters.url_dailymonthly,
      this.props.filters.url_app_identifiers,
    ]);
    this.doFetchData(url);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      var url = appUtils.buildURL(this.state.apiurl, [
        this.props.filters.url_daterange,
        this.props.filters.url_dailymonthly,
        this.props.filters.url_app_identifiers,
      ]);
      this.doFetchData(url);
    }
  }

  render() {
    var chartTitle = 'Sessions saving to Favorites ';
    if (this.props.filters.dailyMonthly === 1) chartTitle += 'by day';
    if (this.props.filters.dailyMonthly === 2) chartTitle += 'by month';
    if (this.props.filters.dailyMonthly === 3) chartTitle += 'by week';
    chartTitle += this.props.filters.text_daterange;
    var chartHeight =
      this.state.ddata == null
        ? '100%'
        : this.state.ddata.length * 50 + //approximate bar size
          (this.state.ddata.length - 1) * 2 + //approximate gap size
          50 + //bottom axis and text, very inprecise
          'px';
    var csvurl = appUtils.buildURL(this.state.apiurl, [
      'format=csv',
      this.props.filters.url_daterange,
      this.props.filters.url_dailymonthly,
      this.props.filters.url_app_identifiers,
    ]);
    return (
      <>
        <div className="graph-title">
          <h3>{chartTitle}</h3>
        </div>
        <ResponsiveContainer width="95%" height="100%" minHeight={chartHeight} maxHeight={chartHeight}>
          <BarChart
            data={this.state.ddata}
            margin={{ top: 0, right: 30, left: 120, bottom: 0 }}
            layout="vertical"
            stackOffset="expand"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" scale="auto" />
            <YAxis dataKey="name" type="category" interval={0} />
            <Tooltip content={(info) => this.showTooltip(info)} />
            <Legend />
            <Bar dataKey="desktop" fill="#3366cc">
              <LabelList dataKey="desktop_label" position="right" />
            </Bar>
            <Bar dataKey="mobile" fill="#DC143C">
              <LabelList dataKey="mobile_label" position="right" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {this.state.ddata != null && (
          <div className="report-download">
            <a href={csvurl}>Download the data of this graph as CSV file</a>
          </div>
        )}
        <div className="graph-explanation">
          This records the percentage of sessions in which the session was saved at least once to the customer's account
          on the web site.
        </div>
      </>
    );
  }

  showTooltip(info) {
    if ('payload' in info) {
      if (Array.isArray(info.payload)) {
        if (info.payload.length > 0) {
          if (info.payload[0]) {
            if ('payload' in info.payload[0]) {
              return <div className="custom-tooltip">{info.payload[0].payload.tooltip}</div>;
            }
          }
        }
      }
    }
    return null;
  }

  doFetchData(url) {
    this.props.functions.spinStart();
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            var ddata = [];
            for (var i in data) {
              ddata.push({
                name: data[i][0],
                desktop: data[i][1][0],
                mobile: data[i][1][1],
                tooltip: data[i][1][0].toFixed(1) + '% on desktop, ' + data[i][1][1].toFixed(1) + '% on mobile',
                desktop_label: data[i][1][0].toFixed(1) + '%',
                mobile_label: data[i][1][1].toFixed(1) + '%',
              });
            }

            if (this.mounted) this.setState({ ddata: ddata });
            this.props.functions.spinStop();
          });
        } else {
          console.log('error 1');
          this.props.functions.spinStop();
        }
      })
      .catch((err) => {
        console.log('error 2');
        this.props.functions.spinStop();
      });
  }
}

export default ReportFeatureSaveRemote;
